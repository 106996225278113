<template>
  <div>
    <form @submit.prevent="save">
      <v-text-field label="Nom *"
                    @input="errors && errors.name ? errors.name = '' : false"
                    :error-messages="errors.name" v-model="form.name"/>
      <v-textarea label="Description"
                  @input="errors && errors.description ? errors.description = '' : false"
                  :error-messages="errors.description" v-model="form.description" rows="2"/>
      <div class="d-flex justify-space-between mt-3">
        <v-spacer/>
        <v-btn color="primary" :loading="btnLoading" depressed class="rounded-lg"
               type="submit">
          <v-icon left>mdi-content-save</v-icon>
          Enregistrer
        </v-btn>
      </div>
    </form>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      btnLoading: false,
      form: {
        id: '',
        name: '',
        description: '',
      },
      errors: {},
    }
  },
  methods: {
    edit(item) {
      this.form.id = item.id
      this.form.name = item.name
      this.form.description = item.description || ''
      this.errors = {}
    },
    add() {
      this.form.id = ''
      this.form.name = ''
      this.form.description = ''
      this.errors = {}
    },
    save() {
      this.btnLoading = true
      this.$Progress.start()
      const url = this.form.id ? '/brands/update' : '/brands/store'
      HTTP.post(url, this.form).then((res) => {
        this.$successMessage = 'Cette marque a été enregistrée avec succès'
        if (this.form.id) {
          this.$emit('close')
        }
        if (!this.form.id) {
          this.brands.unshift(res.data.data)
          this.add()
        } else {
          const i = this.brands.findIndex(item => item.id === this.form.id)
          this.brands.splice(i, 1, res.data.data)
        }
        this.refreshStore()
        this.btnLoading = false
        this.$Progress.finish()
      }).catch(err => {
        this.btnLoading = false
        this.$Progress.fail()
        this.errors = err.response.data.errors
        this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
        console.log(err)
      })
    },
    refreshStore() {
      this.$store.dispatch('brands', this.brands)
    }
  },
  computed: {
    brands() {
      return this.$store.state.brands
    }
  }
}
</script>

<style scoped>

</style>