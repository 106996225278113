<template>
  <div>
    <v-container fluid>
      <v-overlay :value="overlay" opacity="0">
        <v-progress-circular
            indeterminate
            size="50"
            color="primary"
        ></v-progress-circular>
      </v-overlay>
      <v-dialog v-model="dialog" width="500" persistent>
        <v-card>
          <v-card-title>
            {{ dialogTitle }}
            <v-spacer/>
            <v-icon @click="dialog = false">mdi-close</v-icon>
          </v-card-title>
          <v-divider/>
          <v-card-text class="pa-4">
            <BrandForm @close="dialog=false" ref="brandForm"/>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-card class="rounded-lg shadow">
        <v-row no-gutters>
          <v-col cols="3" class="b-r">
            <Navigation/>
          </v-col>
          <v-col cols="9">
            <v-card-title>
              <div>
                <v-text-field v-model="search" dense filled rounded
                              clearable
                              append-icon="mdi-magnify"
                              placeholder="Recherche..."
                              class="w-300"
                              single-line hide-details></v-text-field>
              </div>
              <v-spacer/>
              <v-btn text class="text-none" disabled>
                {{ brands.length }} Élément(s)
              </v-btn>
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="getBrands">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>Actualiser</span>
              </v-tooltip>
              <v-btn color="blue" dark class="ml-1" fab small depressed
                     @click="addItem">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-card-title>
            <v-skeleton-loader v-if="loading" type="table"/>
            <v-data-table v-else :headers="headers" :items="brands" :search="search">
              <template v-slot:item.actions="{ item }">
                <div class="d-flex">
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" v-bind="attrs" v-on="on" icon @click="editItem(item)">
                        <v-icon size="20">mdi-pencil-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Modifier</span>
                  </v-tooltip>
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" v-bind="attrs" v-on="on" icon @click="deleteItem(item.id)">
                        <v-icon size="20">mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Supprimer</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Navigation from "../Navigation";
import {HTTP} from "@/http-common";
import BrandForm from "./BrandForm";

export default {
  components: {BrandForm, Navigation},
  data() {
    return {
      search: '',
      headers: [
        {text: 'Nom', value: 'name'},
        {text: 'Description', value: 'description'},
        {text: 'Actions', value: 'actions', sortable: false},
      ],
      overlay: false,
      dialog: false,
      dialogTitle: '',
      id: '',
      brands: [],
      loading: false,
    }
  },
  methods: {
    addItem() {
      this.dialogTitle = 'Ajouter une marque'
      this.dialog = true
      let _vm = this
      setTimeout(function () {
        _vm.$refs.brandForm.add()
      }, 100)
    },
    editItem(item) {
      this.dialogTitle = 'Modifier une marque'
      this.dialog = true
      let _vm = this
      setTimeout(function () {
        _vm.$refs.brandForm.edit(item)
      }, 100)
    },
    deleteItem(id) {
      this.id = id
      this.$confirm_dialog = true
    },
    getBrands() {
      this.brands = []
      this.loading = true
      this.$Progress.start()
      HTTP.get('/brands').then((res) => {
        this.loading = false
        this.$Progress.finish()
        this.brands = res.data.data
        this.refreshStore()
      }).catch(err => {
        this.$Progress.fail()
        this.loading = false
        console.log(err)
      })
    },
    refreshStore() {
      this.$store.dispatch('brands', this.brands)
    }
  },
  created() {
    this.getBrands()
  },
  watch: {
    '$is_confirm': function (el) {
      if (el) {
        this.$is_confirm = false
        this.overlay = true
        this.$Progress.start()
        HTTP.delete('/brands/delete/' + this.id).then(() => {
          let i = this.brands.findIndex(item => item.id === this.id)
          this.$delete(this.brands, i)
          this.$successMessage = 'Cette marque a été supprimée avec succès'
          this.refreshStore()
          this.overlay = false
          this.$Progress.finish()
        }).catch(err => {
          this.overlay = false
          this.$Progress.fail()
          console.log(err)
        })
      }
    },
  },
}
</script>

<style scoped>

</style>